import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'

import GolfService from '@/views/modules/ultils/GolfService/GolfService.vue'
import SpecialDay from '@/views/modules/ultils/SpecialDay/SpecialDay.vue'
import Weekly from '@/views/modules/ultils/Weekly/Weekly.vue'

import vSelect from 'vue-select'
import { createPopper } from '@popperjs/core'
import '@core/scss/vue/libs/vue-select.scss'
import { packageSetting } from '@/api/package-setting'

import Cleave from 'vue-cleave-component'

const stringMath = require('string-math')

export default {
  name: 'AdditionFee',
  components: {
    Weekly,
    SpecialDay,
    GolfService,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    listCourse: {
      type: Array,
      required: true,
    },
    listTransaction: {
      type: Array,
      required: true,
    },
    inActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentLang: this.$i18n.locale,
      inputMaskCurrency: this.inputMaskByCurrency(),
      isOpen: false,
      listNumberOfHoles: [
        {
          value: 9,
          title: '9 Hole',
        },
        {
          value: 18,
          title: '18 Hole',
        },
        {
          value: 27,
          title: '27 Hole',
        },
        {
          value: 36,
          title: '36 Hole',
        },
        {
          value: 45,
          title: '45 Hole',
        },
        {
          value: 54,
          title: `54 ${this.$t('hole')}`,
        },
      ],
      detailAdditionFee: {},
      listDistributionType: [
        {
          text: this.$t('golf_common_percent'),
          value: 'PERCENT',
        },
        {
          text: this.$t('golf_common_monney'),
          value: 'FIX',
        },
      ],
      packageId: null,
      listWeeklyCode: [
        {
          text: this.$t('golf_common_monday'),
          value: 'MON',
        },
        {
          text: this.$t('golf_common_tuesday'),
          value: 'TUE',
        },
        {
          text: this.$t('golf_common_wednesday'),
          value: 'WED',
        },
        {
          text: this.$t('golf_common_thursday'),
          value: 'THU',
        },
        {
          text: this.$t('golf_common_friday'),
          value: 'FRI',
        },
        {
          text: this.$t('golf_common_saturday'),
          value: 'SAT',
        },
        {
          text: this.$t('golf_common_sunday'),
          value: 'SUN',
        },
      ],
      listDistribution: [],
      isAddNew: true,
      currencyCode: null,
      isValidDistribution: true,
      inputMaskTime: {
        time: true,
        timePattern: ['h', 'm'],
      },
    }
  },
  methods: {
    open(packageId, currencyCode, detail) {
      // Reset khi bấm lần 2
      this.detailAdditionFee = {}
      this.isAddNew = true
      this.listDistribution = []

      this.isOpen = true
      this.packageId = packageId
      this.currencyCode = currencyCode

      this.inputMask = this.inputMaskByCurrency(this.currencyCode)

      if (detail) {
        this.isAddNew = false
        this.detailAdditionFee = JSON.parse(JSON.stringify(detail))
      } else {
        this.detailAdditionFee = {
          CourseId: [],
          Price: null,
          NumberOfHole: [9, 18, 27, 36, 45, 54],
          StartTime: '00:00',
          EndTime: '23:59',
          WeeklyCode: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
          PackageAdditionalFeeDistribution: [],
          DistributionType: 'FIX',
        }
      }

      this.detailAdditionFee.CourseId.forEach(x => {
        const value = (this.detailAdditionFee.PackageAdditionalFeeDistribution.filter(y => y.CourseId === x).length) ? this.detailAdditionFee.PackageAdditionalFeeDistribution.find(y => y.CourseId === x).Amount : 0
        this.listDistribution.push(
          {
            courseId: x,
            amount: value,
          },
        )
      })
    },
    afterUpdateAdditionFee(res) {
      this.$emit('afterUpdateAdditionFee', res)
    },
    addNewAdditionFee_RPA01_AC11() {
      this.$refs.additionFeeForm.validate().then(success => {
        if (success && this.validateDistribution()) {
          const body = {
            Package: {
              Id: this.packageId,
              PackageAdditionalFee: {
                ServiceId: this.detailAdditionFee.ServiceId,
                NumberOfHole: this.detailAdditionFee.NumberOfHole,
                StartTime: this.detailAdditionFee.StartTime,
                EndTime: this.detailAdditionFee.EndTime,
                SpecialDayId: this.detailAdditionFee.SpecialDayId,
                WeeklyCode: this.detailAdditionFee.WeeklyCode,
                Price: parseFloat(this.detailAdditionFee.Price),
                TransactionCode: this.detailAdditionFee.TransactionCode,
                DistributionType: this.detailAdditionFee.DistributionType,
                PackageAdditionalFeeDistribution: [],
                CourseId: this.detailAdditionFee.CourseId,
              },
            },
          }

          this.listDistribution.forEach(x => {
            body.Package.PackageAdditionalFee.PackageAdditionalFeeDistribution.push(
              {
                CourseId: x.courseId,
                Amount: parseFloat(x.amount),
              },
            )
          })

          packageSetting
            .addNewPackageAdditionalFee(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status === '200') {
                this.isOpen = false
                this.afterUpdateAdditionFee(res)
              }
            })
        }
      })
    },
    updateAdditionFee_RPA01_AC12() {
      this.$refs.additionFeeForm.validate().then(success => {
        if (success && this.validateDistribution()) {
          const body = {
            Package: {
              Id: this.packageId,
              PackageAdditionalFee: {
                Id: this.detailAdditionFee.Id,
                ServiceId: this.detailAdditionFee.ServiceId,
                NumberOfHole: this.detailAdditionFee.NumberOfHole,
                StartTime: this.detailAdditionFee.StartTime,
                EndTime: this.detailAdditionFee.EndTime,
                SpecialDayId: this.detailAdditionFee.SpecialDayId,
                CourseId: this.detailAdditionFee.CourseId,
                WeeklyCode: this.detailAdditionFee.WeeklyCode,
                Price: parseFloat(this.detailAdditionFee.Price),
                TransactionCode: this.detailAdditionFee.TransactionCode,
                DistributionType: this.detailAdditionFee.DistributionType,
                PackageAdditionalFeeDistribution: [],
              },
            },
          }

          this.listDistribution.forEach(x => {
            body.Package.PackageAdditionalFee.PackageAdditionalFeeDistribution.push(
              {
                CourseId: x.courseId,
                Amount: parseFloat(x.amount),
              },
            )
          })

          packageSetting
            .updatePackageAdditionalFee(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status == '200') {
                this.isOpen = false
                this.afterUpdateAdditionFee(res)
              }
            })
        }
      })
    },
    updatelistDistribution() {
      this.listDistribution = []
      this.detailAdditionFee.CourseId.forEach(x => {
        this.listDistribution.push(
          {
            courseId: x,
            amount: 0,
          },
        )
      })
    },
    selectSevice(service) {
      // Set giá mặc định của dịch vụ
      this.detailAdditionFee.Price = service.ProductPrice
      // Set transaction code mặc định khi chọn dịch vụ
      this.detailAdditionFee.TransactionCode = service.TransactionCode.TransactionCode
    },
    validateDistribution() {
      let isValid = false
      let total = 0

      this.listDistribution.forEach(x => {
        total += parseFloat(x.amount)
      })

      if (this.detailAdditionFee.DistributionType == 'PERCENT') {
        if (total == 100) {
          isValid = true
        }
      } else if (total == this.detailAdditionFee.Price) {
        isValid = true
      }

      this.isValidDistribution = isValid
      return isValid
    },
    labelCourseDistribution(type, courseId) {
      let courseName = courseId
      if (this.listCourse.filter(x => x.Id == courseId).length > 0) {
        courseName = this.listCourse.find(x => x.Id == courseId).CourseName
      }

      if (type == 'PERCENT') {
        courseName += ' (%)'
      } else {
        courseName = `${courseName} (${this.currencyCode})`
      }
      return courseName
    },
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = '400px'

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
  },
}
