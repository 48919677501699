/* eslint-disable radix */

import vSelect from 'vue-select'

import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'

import DxButton from 'devextreme-vue/button'
import 'devextreme/dist/css/dx.light.css'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'

import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'

import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import { packageSetting } from '@/api/package-setting'
import { commonServices } from '@/api/common-services'
import { courseSetting } from '@/api/course-setting'
import { golfClassSetting } from '@/api/golf-class-setting'
import MasterPackage from './MasterPackage/MasterPackage.vue'

import AdditionFee from './AdditionFee/AdditionFee.vue'
import ContentDetailPackage from './ContentDetailPackage/ContentDetailPackage.vue'

import '@core/scss/vue/libs/vue-select.scss'

const dataGridRef = 'tablePackage'

export default {
  name: 'Package',
  components: {
    ContentDetailPackage,
    AdditionFee,
    MasterPackage,
    vSelect,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
    'delete-dialog': DeleteDialog,
    'confirm-dialog': ConfirmDialog,
  },
  data() {
    return {
      listCourse: [],
      listCompany: [],
      listCurrency: JSON.parse(localStorage.getItem('userData')).currency,
      listSource: [],
      listRateParent: [],
      listGolfClass: [],
      listPackage: [],
      listNumberOfHoles: [
        {
          value: 9,
          title: '9 Hole',
        },
        {
          value: 18,
          title: '18 Hole',
        },
        {
          value: 27,
          title: '27 Hole',
        },
      ],
      listWeeklyCode: [
        {
          text: this.$t('golf_common_monday'),
          value: 'MON',
        },
        {
          text: this.$t('golf_common_tuesday'),
          value: 'TUE',
        },
        {
          text: this.$t('golf_common_wednesday'),
          value: 'WED',
        },
        {
          text: this.$t('golf_common_thursday'),
          value: 'THU',
        },
        {
          text: this.$t('golf_common_friday'),
          value: 'FRI',
        },
        {
          text: this.$t('golf_common_saturday'),
          value: 'SAT',
        },
        {
          text: this.$t('golf_common_sunday'),
          value: 'SUN',
        },
      ],
      listTransaction: [],
      configTable: [
        {
          caption: this.$t('golf_package_code'),
          field: 'RateCode',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'package-code',
          width: '10%',
        },
        {
          caption: this.$t('golf_package_name'),
          field: 'PackageName',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'package-name',
          width: '20%',
          class: 'text-truncate',
        },
        {
          caption: this.$t('golf_package_date_start'),
          field: 'StartDate',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'start-date',
          width: '15%',
        },
        {
          caption: this.$t('golf_package_date_exp'),
          field: 'ExpirationDate',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'exp-date',
          width: '15%',
        },
        {
          caption: this.$t('golf_package_day_apply'),
          field: 'WeeklyCode',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'day-apply',
          width: '15%',
        },
        {
          caption: this.$t('golf_package_course_apply'),
          field: 'CourseId',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'course-apply',
          width: '15%',
        },
        {
          caption: this.$t('golf_package_golf_class'),
          field: 'GolfClassId',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'golf-class',
          width: '15%',
        },
        {
          caption: '',
          field: 'action',
          isShow: true,
          cellTemplate: 'action',
          alignment: 'left',
        },
      ],
      dataGridRef: 'tablePackage',
      timeZone: localStorage.getItem('timeZone'),
      offset: 0,
      search: {
        KeyWord: null,
        courseId: [],
        startDate: this.getUTC('start', 'year'),
        endDate: this.getUTC('end', 'year'),
      },
      currentPackage: null,
      currentPackageStatus: null,
      reload: 1,
      currentPackageName: null,
      isOpenSidebar: false,
      isOpenModal: false,
      isLoading: false,
      testDate: null,
      isDetail: false,
    }
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
  created() {
    this.init_RRA01_AC1()
    this.offset = this.dayjs().utcOffset() - this.dayjs().tz(this.timeZone).utcOffset()
    // alert(this.dayjs().utcOffset() - this.dayjs().tz(this.timeZone).utcOffset())
    // alert(this.dayjs().tz(this.timeZone).utcOffset() / 60)
    // alert(this.dayjs().utcOffset() / 60)
  },
  methods: {
    viewDetailMasterPackage(packageId) {
      this.$refs.masterPackage.open(packageId)
    },
    viewAdditionFee(detail, course, rateId, rateParentId) {
      this.$refs.additionFee.open(detail, course, rateId, rateParentId)
    },
    viewSidebarDetailPackage(item, force) {
      // Xem từ row data hoặc từ nut 3 chấm ở action
      if (item.column && item.column.name != 'action' && item.data.Id || force) {
        this.isOpenSidebar = true
        this.currentPackage = item.data.Id
        this.currentPackageStatus = item.data.InActive
        this.currentPackageName = item.data.PackageName
      }
    },
    afterUpdateMasterPackage() {
      // Load lại thông tin list package
      this.getListPackage_RPA01_AC2()
      // Load lại thông tin detail
      this.$refs.contentDetailPackage.getDetailPackage_RPA02_AC2()
      this.$refs.contentDetailPackageModal.getDetailPackage_RPA02_AC2()
    },
    afterAddNewMasterPackage(res) {
      // Load lại thông tin list rate
      this.getListPackage_RPA01_AC2()
      this.currentPackage = res.Data.Package.Id
      // Mở popup detail package để thêm golf fee
      this.isOpenModal = true
    },
    inActiveRow(e) {
      if (e.rowType !== 'data') { return }
      if (e.data.InActive) {
        e.rowElement.style.backgroundColor = 'rgba(193, 193, 193, 0.12)'
      }
    },
    async init_RRA01_AC1() {
      this.isLoading = true
      // Lấy list sân
      courseSetting
        .getListCourse()
        .then(res => {
          if (res.Status === '200') {
            this.listCourse = res.Data.Course
            this.search.courseId = this.listCourse.map(x => x.Id)
          } else {
            this.showResToast(res)
          }
        })

      // Lấy list package
      this.getListPackage_RPA01_AC2()

      // Lấy danh sách golf class
      const body = {
        KeyWord: null,
        CourseId: [],
      }
      await golfClassSetting
        .getListGolfClass(body)
        .then(res => {
          if (res.Status === '200') {
            this.listGolfClass = res.Data.GolfClass
          } else {
            this.showResToast(res)
          }
        })

      // Lấy danh sách company
      await commonServices
        .getCompany()
        .then(res => {
          if (res.Status === '200') {
            this.listCompany = res.Data.Client
          } else {
            this.showResToast(res)
          }
        })

      // Lấy danh sách đơn vị tiền tệ
      // await commonServices
      //   .getCurrency()
      //   .then(res => {
      //     if (res.Status === '200') {
      //       this.listCurrency = res.Data.CurrencyCode
      //     }
      //   })
      const bodyTrans = {
        hotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
      }
      // Lấy danh sách transaction
      await commonServices
        .getListTransactionCode(bodyTrans)
        .then(res => {
          if (res.Status === '200') {
            this.listTransaction = res.Data.TransactionCode
          } else {
            this.showResToast(res)
          }
        })

      this.isLoading = false
    },
    async getListPackage_RPA01_AC2() {
      const body = {
        PackageCode: this.search.KeyWord,
        CourseId: this.search.courseId,
        StartDate: this.search.startDate,
        ExpirationDate: this.search.endDate,
      }
      await packageSetting
        .getPackageList(body)
        .then(res => {
          if (res.Status === '200') {
            this.listPackage = res.Data.Package.filter(x => !x.IsDeleted)
          } else {
            this.showResToast(res)
          }
        })
    },
    disablePackage_RPA01_AC6_dialog(data) {
      this.disablePackage_RPA01_AC6(data.Id)
    },
    enablePackage_RPA01_AC7_dialog(data) {
      this.enablePackage_RPA01_AC7(data.Id)
    },
    deletePackage_RPA01_AC8_dialog(data) {
      this.deletePackage_RPA01_AC8(data.Id)
    },
    async disablePackage_RPA01_AC6(packageId) {
      const body = {
        Package: {
          Id: packageId,
        },
      }
      await packageSetting
        .disablePackage(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status === '200') {
            // Load lại thông tin detail
            this.getListPackage_RPA01_AC2()
            this.$refs.contentDetailPackage.getDetailPackage_RPA02_AC2()
            this.$refs.contentDetailPackageModal.getDetailPackage_RPA02_AC2()
            this.currentPackageStatus = true
          }
        })
    },
    async enablePackage_RPA01_AC7(packageId) {
      const body = {
        Package: {
          Id: packageId,
        },
      }
      await packageSetting
        .enablePackage(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status === '200') {
            // Load lại thông tin detail
            this.getListPackage_RPA01_AC2()
            this.$refs.contentDetailPackage.getDetailPackage_RPA02_AC2()
            this.$refs.contentDetailPackageModal.getDetailPackage_RPA02_AC2()
            this.currentPackageStatus = false
          }
        })
    },
    async deletePackage_RPA01_AC8(packageId) {
      const body = {
        Package: {
          Id: packageId,
        },
      }
      await packageSetting
        .deletePackage(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status === '200') {
            this.isOpenSidebar = false
            this.isOpenModal = false
            this.getListPackage_RPA01_AC2()
          }
        })
    },
    exportPDF() {
      const pdfDoc = new jsPDF('p', 'pt', 'a4')
      exportDataGridToPdf({
        jsPDFDocument: pdfDoc,
        component: this.dataGrid,
      }).then(() => {
        pdfDoc.setFontSize(12)
        const pageCount = pdfDoc.internal.getNumberOfPages()
        for (let i = 1; i <= pageCount; i++) {
          pdfDoc.setPage(i)
          const { pageSize } = pdfDoc.internal
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          const header = 'Report 2021'
          const footer = `Page ${i} of ${pageCount}`
          const logo = 'iVBORw0KGgoAAAANSUhEUgAAAHUAAAAcCAMAAABoIQAcAAAAw1BMVEUAAAAQSqEQR54QSZ8QSZ8Aod0AoNwAn9sRR50RRZr/7Un/808RS58TRpr/6UMMX64RS6AbM4H+yCEcMoKJglj/zSj7xyQRSp8AoNwRR5wRSJ0RSZ4VQZMUQpYSRpoTRJj/6UP/8EsWPpAAmNgXOosaNIQTRJf/8k3/5T8Andr+3jkAk9T/7kj/4jz+2jQAjtL/7Eb+1zABic4Hdr/+0y3+0CoDhMoEfcVQYnb+zCXHv1bFuVAJZrRUYXOLjGHErkfEqUEir4bvAAAAF3RSTlMAf0D+v3+/QP7+QL+/v3/+v7+/f39/QKzJvtMAAAJISURBVEjH7ZXpctowFIUtWTZLm3TvxRbGAQJJmjS00LAk3d7/qXoXScjTYQI/yo8mZ7B0pdH4m3NkieRZT0hatUsL8FIlx5M+KYqytDYFAJ0cSbogaMlQ9JscRSedAWFtCYJ9nRxBespU8poC/cAk/1y6mnYKVBv3FUhIPgirldIHL6iqeiAJI5WQ+Oz1SSkFRinsAVSyW9GC00+3X7/dffiIUxE1BS/zONOv3Jvavbz8zNjN2/f9alr7fQWUI6vHYmUiLdyX2j07Qypi7zabdZ+xRbuNR4eQ/kkayrJWc8LnoaMAlXJ03kaNA+40L+jefEGsmP2N0Kqu/cfkI361WGQRIu+hcuRixyVbbVrhKamwC7PGR3c6vCEqY9d9xNYccEg4Xbh3B6NhHKjBX6AKlMqYasLscOjN3v4ajYjaGXDCVrKd97xcqlxlbtjiUUwN79eMjqg0Nq68YCx/UGukVtWUzJbtUhJeXvWCPDVnwxmnzf3fVIncNKjceCph2ezP8xEnzEcHzaY2heX4aovNo4CFmtPkbqraSX2BWM744Ryp5NWd1xTNrq7H48hsSzLNM5LL15HgMGr3QjJ+mCA1JFyQVVhdM7VpVrrtppL8X6JuUmEnFc0S9n6CVEo4UK1dzZpUYeRsNs9dhRKzRim+m6iS1mDDVKWoiUvCDu9/TCIq3/7WLuazGVEjrMQqokIUDqL3BtpPiHmkO5vRVffuDTKZili/r9+Xc6I2zWbhxGbxLYHSYpcqgw6FoLY7bZT2JWD5rP9SfwAlY2dKPlPjkQAAAABJRU5ErkJggg=='

          // Add logo
          pdfDoc.addImage(logo, 'JPEG', 40, 10, 50, 25)

          // Header
          pdfDoc.text(header, 120, 20, { baseline: 'top' })

          // Footer
          pdfDoc.text(footer, pageWidth / 2 - (pdfDoc.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' })
        }
      }).then(() => {
        pdfDoc.save('Customers.pdf')
      })
    },
    exportExcel() {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Employees')
      exportDataGrid({
        component: this.dataGrid,
        worksheet,
        autoFilterEnabled: true,
        PrintSelectedRowsOnly: true,
        topLeftCell: { row: 4, column: 1 },
      }).then(cellRange => {
        // header
        const headerRow = worksheet.getRow(2)
        headerRow.height = 30
        worksheet.mergeCells(2, 1, 2, 8)

        headerRow.getCell(1).value = 'Country Area, Population, and GDP Structure'
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
        headerRow.getCell(1).alignment = { horizontal: 'center' }

        // footer
        const footerRowIndex = cellRange.to.row + 2
        const footerRow = worksheet.getRow(footerRowIndex)
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)

        footerRow.getCell(1).value = 'www.wikipedia.org'
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
        footerRow.getCell(1).alignment = { horizontal: 'right' }
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx')
        })
      })
    },
  },
}
