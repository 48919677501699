import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max, alpha_num, integer, required_if, decimal, max_value,
} from '@validations'

import { createPopper } from '@popperjs/core'
import { packageSetting } from '@/api/package-setting'
import Cleave from 'vue-cleave-component'

export default {
  name: 'MasterPackage',
  components: {
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    listCurrency: {
      type: Array,
      required: true,
    },
    listGolfClass: {
      type: Array,
      required: true,
    },
    listCompany: {
      type: Array,
      required: true,
    },
    listTransaction: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      addNewRateEffectiveTimeOptions: [
        {
          title: this.$t('golf_common_date_range'),
          value: 1,
        },
        {
          title: this.$t('golf_common_optional'),
          value: 0,
        },
      ],
      addNewRateEffectiveTime: 1,
      detailMasterPackage: {
        DistributionMethod: 'ByTurn',
        OpenDateSell: this.getUTC('start', 'day'),
        CloseDateSell: this.getUTC('start', 'day'),
        StartDate: this.getUTC('start', 'day'),
        ExpirationDate: this.getUTC('start', 'day'),
      },
      listWeeklyCode: [
        {
          text: this.$t('golf_common_monday'),
          value: 'MON',
        },
        {
          text: this.$t('golf_common_tuesday'),
          value: 'TUE',
        },
        {
          text: this.$t('golf_common_wednesday'),
          value: 'WED',
        },
        {
          text: this.$t('golf_common_thursday'),
          value: 'THU',
        },
        {
          text: this.$t('golf_common_friday'),
          value: 'FRI',
        },
        {
          text: this.$t('golf_common_saturday'),
          value: 'SAT',
        },
        {
          text: this.$t('golf_common_sunday'),
          value: 'SUN',
        },
      ],
      packageId: null,
      inputMask: this.inputMaskByCurrency(),
      isLoading: false,
      length: 0,
    }
  },
  watch: {
    detailMasterPackage: {
      handler(value) {
        this.inputMask = this.inputMaskByCurrency(value.CurrencyCode)
      },
      deep: true,
    },
  },
  methods: {
    open(packageId) {
      this.isOpen = true
      this.packageId = packageId
      if (this.packageId) {
        this.getDetailPackage_RPA02_AC2()
      } else {
        this.detailMasterPackage = {
          DistributionMethod: 'ByTurn',
          OpenDateSell: this.getUTC('start', 'day'),
          CloseDateSell: this.getUTC('start', 'day'),
          StartDate: this.getUTC('start', 'day'),
          ExpirationDate: this.getUTC('start', 'day'),
        }
      }
    },
    afterUpdateMasterPackage(res) {
      this.$emit('afterUpdateMasterPackage', res)
    },
    afterAddNewMasterPackage(res) {
      this.$emit('afterAddNewMasterPackage', res)
    },
    // Chi tiết Master Package
    async getDetailPackage_RPA02_AC2() {
      this.isLoading = true
      const body = {
        Package: {
          Id: this.packageId,
        },
      }
      await packageSetting
        .getDetailPackage(body)
        .then(res => {
          if (res.Status == '200') {
            this.detailMasterPackage = res.Data.Package
          } else {
            this.showResToast(res)
          }
        }).finally(() => {
          this.isLoading = false
        })
    },
    // Thêm mới master package
    addNewMasterPackage_RPA01_AC4() {
      this.$refs.masterRateForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          const body = {
            Package: {
              PackageCode: this.detailMasterPackage.PackageCode,

              PackageName: this.detailMasterPackage.PackageName,
              CurrencyCode: this.detailMasterPackage.CurrencyCode,
              Description: this.detailMasterPackage.Description,
              IsRetail: this.detailMasterPackage.IsRetail,
              IsTaxable: this.detailMasterPackage.IsTaxable,
              IsShare: this.detailMasterPackage.IsShare,
              IsPlayLimit: this.detailMasterPackage.IsPlayLimit,

              Price: parseFloat(this.detailMasterPackage.Price),
              RoundNumber: parseFloat(this.detailMasterPackage.RoundNumber),
              PlayNumber: parseInt(this.detailMasterPackage.PlayNumber),

              OpenDateSell: this.detailMasterPackage.OpenDateSell,
              CloseDateSell: this.detailMasterPackage.CloseDateSell,

              StartDate: this.detailMasterPackage.StartDate,
              ExpirationDate: this.detailMasterPackage.ExpirationDate,

              WeeklyCode: this.detailMasterPackage.WeeklyCode,
              ClientId: this.detailMasterPackage.ClientId,
              GolfClassId: this.detailMasterPackage.GolfClassId,

              DistributionMethod: this.detailMasterPackage.DistributionMethod,
              DistributionParameter: this.detailMasterPackage.DistributionParameter,
              DistributionNotUse: this.detailMasterPackage.DistributionNotUse,
              DistributionParameterDataType: 'FIX',
            },
          }

          packageSetting
            .andNewMasterPackage(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status === '200') {
                this.isOpen = false
                this.afterAddNewMasterPackage(res)
              }
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    // Cập nhật master package
    updateMasterRate_RPA01_AC5() {
      this.$refs.masterRateForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          const body = {
            Package: {
              Id: this.packageId,
              PackageCode: this.detailMasterPackage.PackageCode,

              PackageName: this.detailMasterPackage.PackageName,
              CurrencyCode: this.detailMasterPackage.CurrencyCode,
              Description: this.detailMasterPackage.Description,
              IsRetail: this.detailMasterPackage.IsRetail,
              IsTaxable: this.detailMasterPackage.IsTaxable,
              IsShare: this.detailMasterPackage.IsShare,
              IsPlayLimit: this.detailMasterPackage.IsPlayLimit,

              Price: parseFloat(this.detailMasterPackage.Price),
              RoundNumber: parseFloat(this.detailMasterPackage.RoundNumber),
              PlayNumber: parseInt(this.detailMasterPackage.PlayNumber),

              OpenDateSell: this.detailMasterPackage.OpenDateSell,
              CloseDateSell: this.detailMasterPackage.CloseDateSell,

              StartDate: this.detailMasterPackage.StartDate,
              ExpirationDate: this.detailMasterPackage.ExpirationDate,

              WeeklyCode: this.detailMasterPackage.WeeklyCode,
              ClientId: this.detailMasterPackage.ClientId,
              GolfClassId: this.detailMasterPackage.GolfClassId,

              DistributionMethod: this.detailMasterPackage.DistributionMethod,
              DistributionParameter: this.detailMasterPackage.DistributionParameter,
              DistributionNotUse: this.detailMasterPackage.DistributionNotUse,
              DistributionParameterDataType: 'FIX',
            },
          }

          packageSetting
            .updateMasterPackage(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status == '200') {
                this.isOpen = false
                this.afterUpdateMasterPackage(res)
              }
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = '400px'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
  },
}
